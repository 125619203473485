
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormTextarea from "@/components/form/Textarea.vue";
import CFormSelect from "@/components/form/Select.vue";
import CFormRadio from "@/components/form/Radio.vue";
import CFormImageUpload from "@/components/form/ImageUpload.vue";
import PInputSwitch from "primevue/inputswitch";
import PProgressBar from "primevue/progressbar";

import { conselhos, sexOptions } from "@/libs/utils";
import { formatCPF } from "@/libs/utils";
import { removeCPFMask } from "@/libs/utils";

import { SpecialistForm } from "@/store/specialist/types";

const VSpecialistForm = defineComponent({
  name: "VSpecialistForm",
  components: {
    CModal,
    CFormInputText,
    CFormTextarea,
    CFormSelect,
    CFormRadio,
    CFormImageUpload,
    PInputSwitch,
    PProgressBar,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);
    const isEditForm = route.name?.toString().includes("-edit");

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} especialista | obmed` });

    const specialist = computed(() => store.state.specialist.current);

    const form = reactive<SpecialistForm>({
      cd_estabelecimento: 0,
      cd_uf_conselho: 0,
      ds_observacao: "",
      ie_sexo: "M",
      ie_status: true,
      nm_conselho: "",
      nm_especialista: "",
      nr_conselho: "",
      path_foto: "",
    });
    const loading = reactive({ submit: false, specialist: false });
    const photo = ref<{ photo: string; file?: File }[]>([]);
    const photos = ref<{ photo: string }[]>([]);
    const stateOptions = computed(() =>
      store.state.locality.allStates.map((state) => ({ label: state.nm_estado, value: state.id }))
    );

    async function getSpecialist() {
      loading.specialist = true;
      await store.dispatch("getSpecialist", { _id });
      loading.specialist = false;

      form.nm_especialista = specialist.value?.nm_especialista || "";
      form.ie_sexo = specialist.value?.ie_sexo || "M";
      form.nm_conselho = specialist.value?.nm_conselho || "";
      form.cd_uf_conselho = specialist.value?.cd_uf_conselho?.id || 0;
      form.nr_conselho = specialist.value?.nr_conselho || "";
      form.ds_observacao = specialist.value?.ds_observacao || "";
      form.ie_status = specialist.value?.ie_status ?? true;
      form.cd_estabelecimento = specialist.value?.cd_estabelecimento || 0;

      if (specialist.value?.aq_foto) photos.value = [{ photo: specialist.value?.aq_foto }];
    }

    if (isEditForm) getSpecialist();

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      loading.submit = true;
      const response = isEditForm
        ? await store.dispatch("updateSpecialist", { _id, form: { ...form } })
        : await store.dispatch("createSpecialist", { form: { ...form } });
      if (photo.value?.[0]?.file)
        await store.dispatch("updateSpecialistPhoto", { _id: _id || response?.data.id, aq_foto: photo.value[0].file });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getSpecialists");
        router.replace({ name: "specialist-list" });
      }
    }

    store.dispatch("getStates");

    return {
      ...{ form, photo, photos, loading, stateOptions, isEditForm },
      ...{ handleClose, onSubmit },
      ...{ formatCPF, removeCPFMask, conselhos, sexOptions },
    };
  },
});

export default VSpecialistForm;
